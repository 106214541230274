import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Cell = ({ data }) => (
  <div className="cell-container">
    <article className="mini-post">
      <header>
        <h3>
          <a href={data.link} target="_blank" rel="noreferrer">
            {data.title}
          </a>
        </h3>
        {/* <h6>
          <a href={data.link} target="_blank" rel="noreferrer">
            Gitlab Link: Code
          </a>
        </h6> */}
        {/* <time className="published">{dayjs(data.date).format('MMMM, YYYY')}</time> */}
      </header>
      <a href={data.link} target="_blank" rel="noreferrer" className="image">
        <img src={`${process.env.PUBLIC_URL}${data.image}`} alt={data.title} />
      </a>
      <div className="description">
        <p>
          <u>Description:</u> {data.desc}
        </p>
      </div>
      <div className="toolstech">
        <p>
          <i>Tools/Tech:</i> {data.toolstech}
        </p>
        <a href={data.link} target="_blank" rel="noreferrer">
          <i>Code</i>: {data.codeOn}
          <FontAwesomeIcon icon={data.icon} />
        </a>
      </div>
      {/* <div className="description"></div> */}
    </article>
  </div>
);

Cell.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    link: PropTypes.string,
    image: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
  }).isRequired,
};

export default Cell;
