const routes = [
  {
    index: true,
    label: 'Ishan Makadia',
    path: '/',
  },
  {
    label: 'About',
    path: '/about',
  },
  {
    label: 'Education',
    path: '/education',
  },
  {
    label: 'Experience',
    path: '/experience',
  },
  {
    label: 'Skills',
    path: '/skills',
  },
  // {
  //   label: 'Resume',
  //   path: '/resume',
  // },
  {
    label: 'Projects',
    path: '/projects',
  },
  {
    label: 'Certifications',
    path: '/certifications',
  },
  // {
  //   label: 'Stats',
  //   path: '/stats',
  // },

  {
    label: 'Contact',
    path: '/contact',
  },
];

export default routes;
