import React from 'react';

const TableRow = ({ course, organization, year, certificate, isWorkshop }) => (
  <tr className="table-row">
    <td>
      {course.link ? <a href={course.link}>{course.title}</a> : course.title}
    </td>
    <td width="30%">{organization}</td>
    <td width="10%">{year}</td>
    <td width="10%">
      <a href={certificate.link}>{isWorkshop ? 'Workshop' : 'Certificate'}</a>
    </td>
  </tr>
);

export default TableRow;
