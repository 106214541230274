import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faGitlab } from '@fortawesome/free-brands-svg-icons/faGitlab';
// import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
// import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
// import { faAngellist } from '@fortawesome/free-brands-svg-icons/faAngellist';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faDev } from '@fortawesome/free-brands-svg-icons/faDev';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
// See https://fontawesome.com/icons?d=gallery&s=brands,regular&m=free
// to add other icons.

const data = [
  {
    link: 'https://github.com/intrepid-ishan',
    label: 'Github',
    icon: faGithub,
  },
  {
    link: 'https://gitlab.com/ish.mak',
    label: 'Gitlab',
    icon: faGitlab,
  },
  {
    link: 'https://www.linkedin.com/in/ishanmakadia',
    label: 'LinkedIn',
    icon: faLinkedinIn,
  },
  {
    link: 'https://twitter.com/ishanmakadia',
    label: 'Twitter',
    icon: faTwitter,
  },
  {
    link: 'https://dev.to/ishanmakadia',
    label: 'Dev.to',
    icon: faDev,
  },
  {
    link: 'mailto:ishan.makadia@dal.ca',
    label: 'Email',
    icon: faEnvelope,
  },
  // {
  //   link: 'http://instagram.com/ishanmakadia/',
  //   label: 'Instagram',
  //   icon: faInstagram,
  // },
  // {
  //   link: 'https://www.facebook.com/ishanmakadia/',
  //   label: 'Facebook',
  //   icon: faFacebookF,
  // },
];

export default data;
