import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGitlab } from '@fortawesome/free-brands-svg-icons/faGitlab';
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';

import Main from '../layouts/Main';

import Cell from '../components/Projects/Cell';
import data from '../data/projects';

const Projects = () => (
  <Main
    title="Projects"
    // description="Learn about Ishan Makadia's projects."
  >
    <article className="post" id="projects">
      <header>
        <div className="title">
          <h2>
            <Link to="/projects">Projects</Link>
          </h2>
          {/* <p>A selection of projects that I&apos;m not too ashamed of</p> */}
        </div>
      </header>
      {data.map((project) => (
        <Cell data={project} key={project.title} />
      ))}
      <article className="mini-post cell-container">
        <header>
          <h3>Want to see more projects?</h3>
        </header>
        <div className="description">
          <p>
            Find it on:
            <a
              href="https://github.com/intrepid-ishan?tab=repositories"
              target="_blank"
              rel="noreferrer"
            >
              Github <FontAwesomeIcon icon={faGithub} />
            </a>
            <a
              href="https://gitlab.com/users/ish.mak/projects"
              target="_blank"
              rel="noreferrer"
            >
              Gitlab
              <FontAwesomeIcon icon={faGitlab} />
            </a>
          </p>
        </div>
      </article>
    </article>
  </Main>
);

export default Projects;
