import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env;

// const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

// const LINK = 'https://media.licdn.com/dms/image/D4D03AQEJM5othvsgWw/profile-displayphoto-shrink_800_800/0/1667646904890?e=1688601600&v=beta&t=TLsON1sZnIOFrJX54hqRJPzRymeDvaM5AZ4qjC0Lxas';

const SideBar = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  console.error('currentPath', currentPath);

  return (
    <section id="sidebar">
      <section id="intro">
        <Link to="/" className="logo">
          <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
        </Link>
        <header>
          <h2>Ishan Makadia</h2>
          <p>
            <a href="mailto:ishan.makadia@dal.ca">ishan.makadia@dal.ca</a>
          </p>
        </header>
      </section>

      {/* {currentPath === '/about' ? null : (
        <section className="blurb">
          <>
            <h2>About</h2>
            <p>
              Hello, I&apos;m Ishan, a passionate developer with a strong
              programming foundation. I have worked as a Full Stack Software
              Engineer at <a href="https://www.toddleapp.com/">Toddle</a>{' '}
              (Ed-tech SaaS product used across 100+ countries). I enjoy
              designing and building scalable systems. I am pursuing a
              Master&apos;s degree in Computer Science at{' '}
              <a href="https://www.dal.ca/">Dalhousie University</a> with a
              focus on Cloud and Data. I&apos;m insatiably curious,
              self-motivated and dedicated, which helps me pick up new skills
              quickly. The way technology has changed our lives in the past
              decade inspires me to think open-mindedly and creatively.
            </p>
            <p>
              Hello, I&apos;m Ishan, a passionate developer with a strong
              programming foundation. I have experience as a Full Stack Software
              Engineer at Toddle, where I contributed to the development of
              Ed-tech SaaS product used in over 100 countries. I thrive on
              designing and building scalable systems.
              </p>
            <p>
              <br />
              I&apos;m insatiably curious, self-motivated and dedicated, which
              helps me pick up new skills quickly. The way technology has
              changed our lives in the past decade inspires me to think
              open-mindedly and creatively.
            </p>
          </>
          <ul className="actions">
            <li>
              <Link to="/about" className="button">
                About Me
              </Link>
            </li>
          </ul>
        </section>
      )} */}

      <section id="footer">
        <ContactIcons />
        {/* <p className="copyright">
          &copy; Ishan Makadia <Link to="/">ishanmakadia.com</Link>.
        </p> */}
      </section>
    </section>
  );
};

export default SideBar;
