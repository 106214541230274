import React from 'react';
import { Link } from 'react-router-dom';
import Main from '../layouts/Main';
import educationData from '../data/education';

const Landing = () => (
  <Main title="Education">
    <article className="post" id="education">
      <header>
        <div className="title">
          <h2>
            <Link to="/education">Education</Link>
          </h2>
        </div>
      </header>
      <div className="education">
        {educationData.map((item) => (
          <article className="degree-container">
            <header>
              <h4>{item.degree}</h4>
              {/* <div className="degreeText">{item.degree}</div> */}
              {/* <h4>
                {item.degree} */}
              {/* <a href={item.link}>{item.degree}</a> */}
              {/* </h4> */}
              <p className="school">
                <a href={item.link}>{item.school}</a>, {item.year}
              </p>
              <ul>
                {item.list.map((listItem) => (
                  <li>{listItem}</li>
                ))}
              </ul>
              {/* <h4 className="degree">Relevant Courses</h4> */}
            </header>
          </article>
        ))}
      </div>
    </article>
  </Main>
);

export default Landing;
