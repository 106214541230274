import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import Main from './layouts/Main'; // fallback for lazy pages
import './static/css/main.scss'; // All of our styles

import Landing from './pages/Landing';
import About from './pages/About';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import Certifications from './pages/Certifications';
import Education from './pages/Education';
import Skills from './pages/Skills';
import Projects from './pages/Projects';
import Experience from './pages/Experience';

const { PUBLIC_URL } = process.env;

// Every route - we lazy load so that each page can be chunked
// NOTE that some of these chunks are very small. We should optimize
// which pages are lazy loaded in the future.
// const About = lazy(() => import('./pages/About'));
// const Contact = lazy(() => import('./pages/Contact'));
// const NotFound = lazy(() => import('./pages/NotFound'));
// const Projects = lazy(() => import('./pages/Projects'));
// const Certifications = lazy(() => import('./pages/Certifications'));
// const Education = lazy(() => import('./pages/Education'));
// const Skills = lazy(() => import('./pages/Skills'));
// const Experience = lazy(() => import('./pages/Experience'));

const App = () => (
  <BrowserRouter basename={PUBLIC_URL}>
    {/* <Suspense> */}
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/about" element={<About />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/skills" element={<Skills />} />
      <Route path="/certifications" element={<Certifications />} />
      <Route path="/education" element={<Education />} />
      <Route path="/experience" element={<Experience />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
    {/* </Suspense> */}
  </BrowserRouter>
);

export default App;
