import React, { useEffect } from 'react';
import Main from '../layouts/Main';
import ContactIcons from '../components/Contact/ContactIcons';

const { PUBLIC_URL } = process.env;

const AOS = require('aos');

const Landing = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <Main title="" fullPage>
      <div id="landing">
        <div className="leftContainer">
          <div className="head">
            <p className="name">
              Ishan <strong>Makadia</strong>
            </p>
            <p className="subtitle">
              <strong>Software Engineer</strong>
            </p>
          </div>
          <section id="footer">
            <p className="description"> </p>
            <p className="description-tech">
              Full Stack, Cloud, Data. <br />
              Scalable systems.
              <br />
              <br />
              <br />
            </p>
            <div className="iconsContainer">
              <hr />
              <ContactIcons />
              <hr />
            </div>
          </section>
        </div>

        <div className="rightContainer">
          <div className="circle">
            <span className="circle__btn">
              <img src={`${PUBLIC_URL}/images/me.jpg`} alt="Nits" />
            </span>
            <span className="circle__back-1" />
            <span className="circle__back-2" />
          </div>
        </div>
      </div>
    </Main>
  );
};

export default Landing;
