const education = [
  {
    school: 'Dalhousie University',
    degree: 'Master of Applied Computer Science',
    degreeLink: '',
    link: 'https://www.dal.ca',
    year: '2023 - 2024',
    list: [
      'Relevant Courses: Advance Software Development Concepts, Data Management - Warehousing & Analytics; Advance Cloud Computing, Advance Cloud Architecting, Technology and Innovation, Serverless Data Processing, Advanced Computer Networks',
    ],
  },
  {
    school: 'Nirma University',
    degree: 'Bachelor of Technology in Computer Engineering',
    link: 'https://nirmauni.ac.in',
    year: '2017 - 2021',
    list: [
      'Relevant Courses: Artificial Intelligence, Data Mining, Software Engineering, Design and Analysis of Algorithms, Database Management Systems, Data Structures, Applied Mathematics',
    ],
  },
];

export default education;
