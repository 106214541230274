const skills = [
  {
    label: 'Languages: ',
    list: [
      {
        label: 'Java',
        link: 'https://www.java.com/en/',
      },
      {
        label: 'JavaScript',
        link: 'https://javascript.info/',
      },
      {
        label: 'Typescript',
        link: 'https://www.typescriptlang.org/',
      },
      {
        label: 'C++',
        link: 'https://cplusplus.com/doc/tutorial/',
      },
      {
        label: 'Python',
        link: 'https://www.python.org/',
      },
    ],
  },
  {
    label: 'Web Design: ',
    list: [
      {
        label: 'HTML',
        link: 'https://www.w3schools.com/html/',
      },
      {
        label: 'CSS',
        link: 'https://www.w3schools.com/css/',
      },
      {
        label: 'Sass',
        link: 'https://sass-lang.com/',
      },
      {
        label: 'Material UI',
        link: 'https://mui.com/',
      },
    ],
  },
  {
    label: 'Frameworks: ',
    list: [
      {
        label: 'Spring boot',
        link: 'https://spring.io/',
      },
      {
        label: 'ReactJS',
        link: 'https://react.dev/',
      },
      {
        label: 'React Native',
        link: 'https://reactnative.dev/',
      },
      {
        label: 'ExpressJS',
        link: 'https://expressjs.com/',
      },
      {
        label: 'GraphQL',
        link: 'https://graphql.org/',
      },
      {
        label: 'gRPC',
        link: 'https://grpc.io/',
      },
      {
        label: 'Webpack',
        link: 'https://webpack.js.org/',
      },
      {
        label: 'Apollo',
        link: 'https://www.apollographql.com/',
      },
    ],
  },
  {
    label: 'Databases: ',
    list: [
      {
        label: 'MySQL',
        link: 'https://www.mysql.com/',
      },
      {
        label: 'PostgreSQL',
        link: 'https://www.postgresql.org/',
      },
      {
        label: 'MongoDB',
        link: 'https://www.mongodb.com/atlas/database',
      },
      {
        label: 'Firebase',
        link: 'https://firebase.google.com/',
      },
      {
        label: 'Neo4j',
        link: 'https://neo4j.com/',
      },
    ],
  },
  {
    label: 'Testing: ',
    list: [
      {
        label: 'Test Driven Development (TDD)',
        link: 'https://en.wikipedia.org/wiki/Test-driven_development',
      },
      {
        label: 'JUnit 5',
        link: 'https://junit.org/junit5/docs/current/user-guide/',
      },
    ],
  },
  {
    label: 'DevOps: ',
    list: [
      {
        label: 'Docker',
        link: 'https://www.docker.com/',
      },
      {
        label: 'Kubernetes',
        link: 'https://kubernetes.io/',
      },
      {
        label: 'CI-CD',
        link: 'https://about.gitlab.com/topics/ci-cd/cicd-pipeline/',
      },
      {
        label: 'Git',
        link: 'https://git-scm.com/',
      },
      {
        label: 'Sentry',
        link: 'https://sentry.io/welcome/',
      },
    ],
  },
  {
    label: 'Other: ',
    list: [
      {
        label: 'ETL Pipeline',
        link: 'https://www.estuary.dev/what-is-an-etl-pipeline/',
      },
      {
        label: 'SOLID Principles',
        link: 'https://en.wikipedia.org/wiki/SOLID',
      },
      {
        label: 'Code Smells',
        link: 'https://refactoring.guru/refactoring/smells',
      },
      {
        label: 'SDLC',
        link: 'https://aws.amazon.com/what-is/sdlc/',
      },
    ],
  },
  // {
  //   label: 'Tools: ',
  //   list: [
  //     {
  //       label: 'VS Code',
  //       link: 'https://code.visualstudio.com/',
  //     },
  //     {
  //       label: 'WebStorm',
  //       link: 'https://www.jetbrains.com/webstorm/',
  //     },
  //     {
  //       label: 'Data Grip',
  //       link: 'https://www.jetbrains.com/datagrip/',
  //     },
  //     {
  //       label: 'Gitlab',
  //       link: 'https://about.gitlab.com',
  //     },
  //     {
  //       label: 'GitHub',
  //       link: 'https://github.com',
  //     },
  //     {
  //       label: 'Jira',
  //       link: 'https://www.atlassian.com/software/jira',
  //     },
  //     {
  //       label: 'Coda',
  //       link: 'https://coda.io',
  //     },
  //     {
  //       label: 'Notion',
  //       link: 'https://www.notion.so/',
  //     },
  //     {
  //       label: 'Sentry',
  //       link: 'https://sentry.io/welcome/',
  //     },
  //     {
  //       label: 'Miro',
  //       link: 'https://miro.com',
  //     },
  //     {
  //       label: 'Figma',
  //       link: 'https://www.figma.com',
  //     },
  //     {
  //       label: 'XCode',
  //       link: 'https://developer.apple.com/xcode/',
  //     },
  //   ],
  // },
  {
    label: 'Non-Tech Skills: ',
    list: [
      {
        label: 'Leadership',
        link: '',
      },
      {
        label: 'Time-management',
        link: '',
      },
      {
        label: 'Effective Communication',
        link: '',
      },
      {
        label: 'Active Learning',
        link: '',
      },
      {
        label: 'Teamwork',
        link: '',
      },
    ],
  },
];

export default skills;
