import React from 'react';
import { Link } from 'react-router-dom';
import Main from '../layouts/Main';
import skillsData from '../data/skills';

const Skills = () => (
  <Main title="Skills">
    <article className="post" id="skills">
      <header>
        <div className="title">
          <h2>
            <Link to="/skills">Skills</Link>
          </h2>
        </div>
      </header>
      <div className="skills">
        {skillsData.map((category) => (
          <div className="skill">
            <span>
              <b>{category.label}</b>
            </span>{' '}
            {category.list.map((item, i) => (
              <span>
                {item.link ? <a href={item.link}>{item.label}</a> : item.label}
                {i !== category.list.length - 1 ? ', ' : ''}
              </span>
            ))}
            {/* JavaScript, Java, C++, Python, PHP, HTML, CSS */}
          </div>
        ))}

        {/* <div className="skill">
          <span>
            <b>Libraries/Frameworks:</b>
          </span>
          ReactJS, React Native, ExpressJS, Spring boot
        </div>
        <div className="skill">
          <span> Databases: </span> ReactJS, React Native, ExpressJS, Spring
          boot
        </div>
        <div className="skill">
          <span> Other: </span> AWS, Serverless, GraphQL, Apollo Client, Apache,
          Git, Redux, Sass, Webpack, SDLC, SRS, CI-CD
        </div>
        <div className="skill">
          <span> Tools: </span> VS Code, WebStorm, Data Grip, Gitlab, GitHub,
          Jira, Coda, Notion, Sentry, Miro, Figma, XCode
        </div>
        <div className="skill">
          <span> Non-Tech Skills: </span> Leadership, Time-management, and
          Effective Communication
        </div> */}
      </div>
    </article>
  </Main>
);

export default Skills;
//   <div className="education">
//     {educationData.map((item) => (
//       <article className="degree-container">
//         <header>
//           <div className="degreeText">{item.degree}</div>
//           {/* <h4>
//             {item.degree} */}
//           {/* <a href={item.link}>{item.degree}</a> */}
//           {/* </h4> */}
//           <p className="school">
//             <a href={item.link}>{item.school}</a>, {item.year}
//           </p>
//           <ul>
//             {item.list.map((listItem) => (
//               <li>{listItem}</li>
//             ))}
//           </ul>
//           {/* <h4 className="degree">Relevant Courses</h4> */}
//         </header>
//       </article>
//     ))}
//   </div>
