import React from 'react';
import certifications from '../../data/certifications';
import TableRow from './TableRow';

const Table = () => (
  <table>
    <tbody>
      {certifications.map((object) => (
        <TableRow
          key={object.link}
          course={object.course}
          organization={object.organization}
          certificate={object.certificate}
          year={object.year}
          isWorkshop={object?.isWorkshop}
        />
      ))}
    </tbody>
  </table>
);

export default Table;
