import React from 'react';
import { Link } from 'react-router-dom';
import Main from '../layouts/Main';
import Table from '../components/Certifications/Table';

const Landing = () => (
  <Main title="Certifications">
    <article className="post" id="certifications">
      <header>
        <div className="title">
          <h2>
            <Link to="/certifications">Certifications</Link>
          </h2>
        </div>
      </header>
      <Table />
    </article>
  </Main>
);
export default Landing;
